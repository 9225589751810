<template>
  <div>
    <details-table
      :share-items="holdersDetails"
      :title="'Shareholder'"
    />
    <details-table
      :share-items="certificateDetails"
      :title="'Certificates'"
    />
    <details-table
      :share-items="issuerDetails"
      :title="'Issuer'"
    />
    <details-table
      v-if="termType===CONSTS.SHARE_TERM_TYPES.CONVERTIBLE"
      :share-items="convertibleDetails"
      :title="'Convertible Terms'"
    />
    <details-table
      :share-items="shareTermData"
      :title="'Underlying Share Terms'"
    />
  </div>
</template>

<script>
  import DetailsTable from './DetailsTable'
  export default {
    name: 'SecurityDetailIndex',
    components: {
      DetailsTable,
    },
    props: {
      shareHolder: {
        type: Object,
        default: () => {},
      },
      certificateDetails: {
        type: Object,
        default: () => {},
      },
      issuerDetails: {
        type: Object,
        default: () => {},
      },
      shareTermData: {
        type: Object,
        default: () => {},
      },
      docsData: {
        type: Array,
        default: () => [],
      },
      notesData: {
        type: Array,
        default: () => [],
      },
      holdersDetails: {
        type: Object,
        default: () => {},
      },
      convertibleDetails: {
        type: Object,
        default: () => {},
        required: false,
      },
      termType: {
        type: String,
        default: '',
        required: false,
      },
    },
    data: () => ({
    }),
  }
</script>
