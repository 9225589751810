import { render, staticRenderFns } from "./TransactionHistory.vue?vue&type=template&id=f4601182&scoped=true&"
import script from "./TransactionHistory.vue?vue&type=script&lang=js&"
export * from "./TransactionHistory.vue?vue&type=script&lang=js&"
import style0 from "./TransactionHistory.vue?vue&type=style&index=0&id=f4601182&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4601182",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDialog,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VFadeTransition,VIcon,VRow})
