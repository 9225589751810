<template>
  <common-trs-page-container class="py-0">
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
    />
    <common-app-page-header-v2
      :title="securityName"
      :has-back-button="true"
    >
      <template v-slot:actions>
        <div class="d-flex justify-space-between">
          <div />
          <common-trs-btn
            type="primary"
            large
            @click="print()"
          >
            print
          </common-trs-btn>
        </div>
      </template>
    </common-app-page-header-v2>
    <v-row>
      <v-col
        v-if="!viewTransactionModal"
        cols="12"
        md="8"
      >
        <warrant-index
          v-if="type == CONSTS.SHARE_TERM_TYPES.WARRANT"
          :warrant-term-data="warrantTermData"
          :warrant-data="warrantData"
          :financing-data="financingData"
          :docs-data="docsData"
          :notes-data="notesData"
          :underlying-share-term="underlyingShareTerm"
          :holders-details="shareHolderData"
        />
        <share-index
          v-if="type == CONSTS.SHARE_TERM_TYPES.COMMON || type == CONSTS.SHARE_TERM_TYPES.PREFERRED"
          :share-term-data="underlyingShareTerm"
          :share-data="shareData"
          :financing-data="financingData"
          :docs-data="docsData"
          :notes-data="notesData"
          :holders-details="shareHolderData"
        />
        <convertible-index
          v-if="type == CONSTS.SHARE_TERM_TYPES.CONVERTIBLE"
          :convertible-term-data="convertibleTermData"
          :underlying-share-term="underlyingShareTerm"
          :convertible-data="convertibleData"
          :financing-data="financingData"
          :docs-data="docsData"
          :notes-data="notesData"
          :holders-details="shareHolderData"
          :term-type="CONSTS.SHARE_TERM_TYPES.CONVERTIBLE"
        />
        <option-index
          v-if="type == CONSTS.SHARE_TERM_TYPES.OPTION"
          :option-term-data="optionTermData"
          :underlying-share-term="underlyingShareTerm"
          :option-data="optionData"
          :docs-data="docsData"
          :notes-data="notesData"
          :holders-details="shareHolderData"
          :term-type="CONSTS.SHARE_TERM_TYPES.OPTION"
        />
        <div class="container px-5">
          <div class="px-2 py-3 text-h4 font-weight-medium">
            Notes:
          </div>
          <div class="notes-card">
            <div
              v-for="(item,index) in docAndNotesId"
              :key="index"
              class="pa-4"
            >
              <view-notes
                :id="item.id"
                :type="item.type"
                :title="item.title"
              />
            </div>
          </div>
        </div>
        <div class="container px-5">
          <div class="px-2 py-3 text-h4 font-weight-medium">
            Documents:
          </div>
          <div class="notes-card">
            <div
              v-for="(item,index) in docAndNotesId"
              :key="index"
              class="pa-4"
            >
              <view-documents
                :id="item.id"
                :type="item.type"
                :sub-title="item.title"
                :documents-handler="getDocuments"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="text-center"
      >
        <!-- Certificate Section -->
        <security-certificate
          v-if="!viewTransactionModal"
          :security-id="securityId"
          :security-type="securityDataType"
          :loading="loading"
          @stopLoader="loading=false"
        />
        <!-- Vesting Schedhule Modal -->
        <div
          v-if="isVestingAvailable"
          class="text-center my-3"
        >
          <v-dialog
            v-model="dialog"
            fullscreen
            scrollable
            v-bind="$attrs"
            v-on="$listeners"
          >
            <template v-slot:activator="{ on, attrs }">
              <common-trs-btn
                type="secondary"
                v-bind="attrs"
                v-on="on"
              >
                View Vesting Scedule
              </common-trs-btn>
            </template>
            <common-trs-modal-card>
              <common-trs-modal-card-title>
                <common-trs-modal-card-title-content>
                  View vesting schedule
                </common-trs-modal-card-title-content>

                <common-trs-btn
                  aria-label="Close"
                  type="secondary"
                  class="rounded-0"
                  color="primary"
                  icon
                  text
                  @click="close"
                >
                  <v-icon>mdi-close</v-icon>
                </common-trs-btn>
              </common-trs-modal-card-title>
              <common-trs-modal-card-text>
                <vesting-schedule-summary
                  :share-details="shareData"
                  :is-vesting-available="isVestingAvailable"
                  :is-modal-closed="false"
                  :holders-data="shareHolderData"
                  @close="close"
                  @reload="$emit('reload')"
                />
              </common-trs-modal-card-text>
            </common-trs-modal-card>
          </v-dialog>
        </div>
        <!-- Transaction History Modal -->
        <security-transaction-history
          v-if="(transactionHistory && transactionHistory.length > 0)"
          :transaction-history="transactionHistory"
          :security-name="securityName"
          :original-issuance="originalIssuance"
          :security-type="type"
          :linked-security-name="linkedSecurityName"
          :linked-security-type="linkedSecurityType"
          @close="viewTransactionModal=false"
        >
          <template v-slot:activator="{ on }">
            <common-trs-btn
              class="mt-3"
              type="secondary"
              v-on="on"
              @click="viewTransactionModal=true"
            >
              Transaction history
            </common-trs-btn>
          </template>
        </security-transaction-history>
      </v-col>
    </v-row>
  </common-trs-page-container>
</template>

<script>
  import WarrantIndex from './warrants/WarrantDetails'
  import ShareIndex from './shares/ShareDetails'
  import ConvertibleIndex from './convertibles/ConvertibleDetailsDetails'
  import OptionIndex from './options/OptionDetailsDetails'
  import * as captableService from '@/components/common/captable/services/captable'
  import ViewNotes from '@/components/common/captable/components/ViewNotes'
  import ViewDocuments from '@/components/common/captable/components/ViewDocuments'
  import SecurityTransactionHistory from './TransactionHistory'
  import VestingScheduleSummary from '../../securities/components/actions/shares/VestingScheduleSummary'
  import SecurityCertificate from './../components/SecurityCertificate'

  export default {
    name: 'SecurityIndex',

    components: {
      WarrantIndex,
      ViewNotes,
      ViewDocuments,
      ShareIndex,
      SecurityTransactionHistory,
      VestingScheduleSummary,
      ConvertibleIndex,
      OptionIndex,
      SecurityCertificate,
    },
    props: {
      reload: {
        type: Boolean,
        default: false,
        required: false,
      },
    },

    data: () => ({
      warrantTermData: {},
      warrantData: {},
      shareData: {},
      convertibleTermData: {},
      convertibleData: {},
      optionTermData: {},
      optionData: {},
      financingData: {},
      docIds: [],
      docsData: [],
      notesData: [],
      underlyingShareTerm: {},
      loading: false,
      docAndNotesId: [],
      getDocuments: false,
      dialog: false,
      securityDataType: '',
      securityId: '',
      viewTransactionModal: false,
      linkedSecurityName: '',
      linkedSecurityType: '',
      shareTransactionList: [],
    }),

    computed: {
      isVestingAvailable () {
        const shareData = this.shareData.vest_actions && this.shareData.vest_actions.length > 0
        const optionData = this.optionData.vest_actions && this.optionData.vest_actions.length > 0
        return shareData || optionData
      },
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      holdersData () {
        return this.$store.getters['auth/holder']
      },
      selectedCaptableHoldersData () {
        return this.$store.getters['auth/selectedCaptableHolder']
      },
      id () {
        return this.$route.params.id
      },
      type () {
        return this.$route.params.type
      },
      securityName () {
        switch (this.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return this.underlyingShareTerm.name
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return this.warrantTermData.name
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return this.convertibleTermData.name
          case this.CONSTS.SHARE_TERM_TYPES.OPTION:
            return this.optionTermData.name
          default: return 'Security'
        }
      },
      transactionHistory () {
        switch (this.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return this.shareTransactionList || []
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return this.warrantData.actions && this.warrantData.actions.length > 0 ? this.warrantData.actions : null
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return this.convertibleData.actions && this.convertibleData.actions.length > 0 ? this.convertibleData.actions : null
          case this.CONSTS.SHARE_TERM_TYPES.OPTION:
            return this.optionData.actions && this.optionData.actions.length > 0 ? this.optionData.actions : null
          default: return null
        }
      },
      originalIssuance () {
        switch (this.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return this.shareData
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return this.warrantData
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return this.convertibleData
          case this.CONSTS.SHARE_TERM_TYPES.OPTION:
            return this.optionData
          default: return null
        }
      },
      shareHolderData () {
        return this.getShareHolder(this.shareData?.holder_id)
      },
    },

    watch: {
      id: {
        handler (val) {
          if (val) {
            this.getSecurityByType()
          }
        },
        immediate: true,
      },
    },

    methods: {
      async getSecurityByType () {
        this.loading = true
        switch (this.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            await this.getShareDetail(this.captableId, this.id)
            await this.getShareTransactions()
            break
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            await this.getWarrant(this.captableId, this.id)
            break
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            await this.getConvertible(this.captableId, this.id)
            break
          case this.CONSTS.SHARE_TERM_TYPES.OPTION:
            await this.getOption(this.captableId, this.id)
        }
      },

      async getWarrant (captableId, warrantId) {
        try {
          const resp = await captableService.retrieveWarrantById(captableId, warrantId)
          if (resp && resp.data) {
            this.warrantData = resp.data
            this.securityId = this.warrantData.id
            this.securityDataType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS
            this.docAndNotesId.push({ title: 'Warrant', id: this.securityId, type: this.securityDataType })
            this.docAndNotesId.push({ title: 'Warrant Terms', id: this.warrantData.warrant_terms_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANT_TERMS })
            this.docAndNotesId.push({ title: 'Underlying Share Term', id: this.warrantData.share_terms_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS })
            this.docAndNotesId.push({ title: 'Financing  Round', id: this.warrantData.financing_round_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.FINANCING_ROUNDS })

            await this.getWarrantTerm(this.warrantData.warrant_terms_id)
            await this.getUnderLyingShareTerm(this.warrantData.share_terms_id)
            await this.getFinancingRound(this.warrantData?.financing_round_id || this.underlyingShareTerm?.financing_round_id)
          }
        } catch (e) {
          this.loading = false
        }
      },

      async getShareDetail (captableId, shareId) {
        try {
          const resp = await captableService.retrieveShareById(captableId, shareId)
          if (resp && resp.data) {
            this.shareData = resp.data
            this.securityId = this.shareData.id
            this.securityDataType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES
            this.docAndNotesId.push({ title: 'Share', id: this.securityId, type: this.securityDataType })
            this.docAndNotesId.push({ title: 'Underlying Share Term', id: this.shareData.share_terms_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS })
            this.docAndNotesId.push({ title: 'Financing  Round', id: this.shareData.financing_round_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.FINANCING_ROUNDS })

            // this.getWarrantTerm(this.shareData.share_terms_id)
            await this.getUnderLyingShareTerm(this.shareData.share_terms_id)
            await this.getFinancingRound(this.shareData?.financing_round_id || this.underlyingShareTerm?.financing_round_id)
          }
        } catch (e) {
          this.loading = false
        }
      },
      async getConvertible (captableId, convertibleId) {
        try {
          const resp = await captableService.retrieveConvertibleById(captableId, convertibleId)
          if (resp && resp.data) {
            this.convertibleData = resp.data
            this.securityId = this.convertibleData.id
            this.securityDataType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES
            this.docAndNotesId.push({ title: 'Convertible', id: this.securityId, type: this.securityDataType })
            this.docAndNotesId.push({ title: 'Convertible Terms', id: this.convertibleData.convertible_terms_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS })
            this.docAndNotesId.push({ title: 'Underlying Share Term', id: this.convertibleData.share_terms_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS })
            this.docAndNotesId.push({ title: 'Financing  Round', id: this.convertibleData.financing_round_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.FINANCING_ROUNDS })

            await this.getConvertibleTerm(this.convertibleData?.convertible_terms_id)
            await this.getUnderLyingShareTerm(this.convertibleData?.share_terms_id)
            await this.getFinancingRound(this.convertibleData?.financing_round_id || this.underlyingShareTerm?.financing_round_id)
          }
        } catch (e) {
          this.loading = false
        }
      },

      async getOption (captableId, optionId) {
        try {
          const resp = await captableService.retrieveOptionById(captableId, optionId)
          if (resp && resp.data) {
            this.optionData = resp.data
            this.securityId = this.optionData.id
            this.securityDataType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS
            this.docAndNotesId.push({ title: 'Option', id: this.securityId, type: this.securityDataType })
            this.docAndNotesId.push({ title: 'Option Terms', id: this.optionData.equity_incentive_plan_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS })
            this.docAndNotesId.push({ title: 'Underlying Share Term', id: this.optionData.share_terms_id, type: this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS })

            this.getOptionTerm(this.optionData.equity_incentive_plan_id)
            this.getUnderLyingShareTerm(this.optionData.share_terms_id)
          }
        } catch (e) {
          this.loading = false
        }
      },

      async getWarrantTerm (termId) {
        try {
          const resp = await captableService.getWarrantTermById(this.captableId, termId)
          if (resp && resp.data) {
            this.warrantTermData = resp.data
          }
        } catch (e) {
          this.loading = false
        }
      },

      async getConvertibleTerm (termId) {
        try {
          const resp = await captableService.getConvertibleTermById(this.captableId, termId)
          if (resp && resp.data) {
            this.convertibleTermData = resp.data
          }
        } catch (e) {
          this.loading = false
        }
      },

      async getOptionTerm (termId) {
        try {
          const resp = await captableService.getEipById(this.captableId, termId)
          if (resp && resp.data) {
            this.optionTermData = resp.data
          }
        } catch (e) {
          this.loading = false
        }
      },

      async getUnderLyingShareTerm (shareTermId) {
        if (shareTermId) {
          try {
            const resp = await captableService.getShareTermById(this.captableId, shareTermId)
            if (resp && resp.data) {
              this.underlyingShareTerm = resp.data
            }
          } catch (e) {}
        }
      },

      async getFinancingRound (financingRoundId) {
        if (financingRoundId) {
          try {
            const resp = await captableService.getFinancingRoundById(this.captableId, financingRoundId)
            if (resp && resp.data) {
              this.financingData = resp.data
            }
          } catch (e) {
            this.loading = false
          }
        }
      },

      getTransactionHistory () {
        switch (this.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return this.shareData.actions
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return this.warrantData.actions
        }
      },

      getShareHolder (holderId) {
        const holdersData = this.selectedCaptableHoldersData?.length > 0 ? this.selectedCaptableHoldersData?.find((holder) => holder?.id === holderId) : this.holdersData
        const currentHolder = {}
        currentHolder.name = holdersData?.first_name ? holdersData?.first_name.concat(' ', holdersData?.last_name || '') : holdersData?.name
        currentHolder.email = holdersData?.email || holdersData?.username
        return currentHolder
      },

      async getShareTransactions () {
        try {
          const transactions = this.shareData?.actions || []
          if (this.shareData?.exercised_from_warrant_id || this.shareData?.exercised_from_option_id) {
            await this.fetchLinkedWarrant()
            await this.fetchLinkedOption()
            const transaction = {
              action: {
                action_id: '',
                exercised_on: this.shareData.granted_on,
                from_security_id: this.shareData.exercised_from_warrant_id,
                holder_id: this.shareData.holder_id,
                number: this.shareData.number,
                price_per_share: this.shareData.price_per_share,
                registration_name: this.shareData.registration_name,
                share_terms_id: this.shareData.share_terms_id,
                to_security_id: this.shareData.share_terms_id,
                type: 'exercise',
              },
            }
            transactions.push(transaction)
          }
          this.shareTransactionList = transactions
        } catch (e) {}
      },
      async fetchLinkedWarrant () {
        if (!this.linkedSecurityName && this.shareData?.exercised_from_warrant_id) {
          const warrantResponse = await captableService.retrieveWarrantById(this.captableId, this.shareData.exercised_from_warrant_id)
          const linkedWarrant = warrantResponse.data
          const warrantTermResponse = await captableService.getWarrantTermById(this.captableId, linkedWarrant?.warrant_terms_id)
          const linkedWarrantTerm = warrantTermResponse.data
          this.linkedSecurityName = linkedWarrantTerm.name
          this.linkedSecurityType = this.CONSTS.SHARE_TERM_TYPES.WARRANT
        }
      },
      async fetchLinkedOption () {
        if (!this.linkedSecurityName && this.shareData?.exercised_from_option_id) {
          const optionResponse = await captableService.retrieveOptionById(this.captableId, this.shareData.exercised_from_option_id)
          const linkedOption = optionResponse.data
          const eipResponse = await captableService.getEipById(this.captableId, linkedOption?.equity_incentive_plan_id)
          const linkedEip = eipResponse.data
          this.linkedSecurityName = linkedEip.name
          this.linkedSecurityType = this.CONSTS.SHARE_TERM_TYPES.OPTION
        }
      },

      close () {
        this.dialog = false
        this.$emit('close')
      },

      print () {
        window.print()
      },
    },
  }
</script>
<style lang="scss" scoped>
.notes-card{
  border: 1px solid #e5e5e5;
}
@media print {
  @page {
    size: 500mm 600mm;
    margin: 12mm;
  }
  .container {
    width: 1300px;
  }
}
</style>
