var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",staticClass:"ml-auto"},[_c('div',{staticClass:"print-btn mb-5"},[_c('common-trs-btn',{attrs:{"type":"primary","large":""},on:{"click":function($event){return _vm.print()}}},[_vm._v(" print ")])],1),_c('securities-summary-view',{attrs:{"security-type":_vm.securityType,"security-details":_vm.securityDetails,"holder-details":_vm.holderDetails,"is-vesting-available":_vm.isVestingAvailable}}),_c('common-trs-card',{staticClass:"pa-2"},[_c('div',{staticClass:"d-flex justify-space-between my-5"},[_c('div',[_c('span',[_vm._v(" Vested as of today: ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$number(_vm.vestedAsOfToday()))+" ("+_vm._s(_vm.vestedAsOfPercent)+"%) ")])]),_c('common-trs-btn',{staticClass:"mx-1 rounded-0",attrs:{"type":"tertiary","color":"primary"},on:{"click":_vm.toggleChart}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.isChart ? 'mdi-trending-up ':'mdi-table')+" ")]),_vm._v(" "+_vm._s(_vm.isChart ? 'View Chart':'View Table')+" ")],1)],1),(_vm.isChart)?_c('common-trs-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.serial_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serial_no)+" ")]}},{key:"item.vested_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vested_on)+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.number === 0 ? 'NA': _vm.$number(item.number))+" ")]}},{key:"item.cumulative",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cumulative === 0 ? 'NA': _vm.$number(item.cumulative))+" ")]}},{key:"item.vest_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.vest_type)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}}],null,true)}):_c('plotly',{attrs:{"data":_vm.chartData,"layout":_vm.chartLayout,"static-plot":true}})],1),_c('v-row',[_c('v-col',[_c('v-divider'),_c('div',{staticClass:"mt-2 d-flex justify-space-between"},[_c('common-trs-btn',{staticClass:"mr-2",attrs:{"type":"secondary","medium":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }