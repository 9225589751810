<template>
  <v-dialog
    v-model="internalDialog"
    fullscreen
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          Transaction History
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <template>
          <div class="float-right mb-5">
            <common-trs-btn
              type="primary"
              large
              @click="print()"
            >
              print
            </common-trs-btn>
          </div>
          <v-expansion-panels>
            <!-- Original Issuance -->
            <v-expansion-panel>
              <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    :md="open ? 6 : 8"
                    class="text-capitalize"
                  >
                    Original Issuance
                  </v-col>
                  <v-col
                    cols="12"
                    :md="open ? 6 : 4"
                    class="text--secondary"
                  >
                    <v-fade-transition leave-absolute>
                      <v-row
                        v-if="open"
                        no-gutters
                        style="width: 100%"
                        :class="{ 'mt-3': $vuetify.breakpoint.smAndDown }"
                      >
                        <v-col>
                          Date
                        </v-col>
                        <v-col>
                          Shares
                        </v-col>
                        <v-col>
                          Price
                        </v-col>
                        <v-col>
                          Registration
                        </v-col>
                      </v-row>
                      <v-row
                        v-else
                        no-gutters
                        style="width: 100%"
                      >
                        <v-col>
                          {{ $date(originalIssuance.granted_on) }}
                        </v-col>
                        <v-col>
                          {{ $number(originalIssuance.number_granted) }} shares
                        </v-col>
                      </v-row>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  v-if="securityName"
                  no-gutters
                  class="mr-5"
                >
                  <v-col
                    cols="6"
                    md="3"
                    class="primary--text"
                  >
                    {{ securityName }}
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    class="primary--text"
                  >
                    <share-term-badge
                      :show-single="true"
                      :type="securityType"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-row
                      no-gutters
                      style="width: 100%"
                      class="text-capitalize"
                    >
                      <v-col>
                        {{ $date(originalIssuance.granted_on) }}
                      </v-col>
                      <v-col>
                        {{ $number(originalIssuance.number_granted) }}
                      </v-col>
                      <v-col>
                        {{ $currencyWithPrecision(originalIssuance.price_per_share) }}
                      </v-col>
                      <v-col>
                        {{ originalIssuance.registration_name }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Transactions -->
            <v-expansion-panel
              v-for="(transaction, key) of transactionHistory"
              :key="key"
            >
              <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    :md="open ? 6 : 8"
                    class="text-capitalize"
                  >
                    {{ transaction.action.type }}
                  </v-col>
                  <v-col
                    cols="12"
                    :md="open ? 6 : 4"
                    class="text--secondary"
                  >
                    <v-fade-transition leave-absolute>
                      <v-row
                        v-if="open"
                        no-gutters
                        style="width: 100%"
                        :class="{ 'mt-3': $vuetify.breakpoint.smAndDown }"
                      >
                        <v-col>
                          Date
                        </v-col>
                        <v-col>
                          Shares
                        </v-col>
                        <v-col>
                          Price
                        </v-col>
                        <v-col>
                          Registration
                        </v-col>
                      </v-row>
                      <v-row
                        v-else
                        no-gutters
                        style="width: 100%"
                      >
                        <v-col>
                          {{ $date(getTransactionDate(transaction.action)) }}
                        </v-col>
                        <v-col>
                          {{ $number(transaction.action.number) }} shares
                        </v-col>
                      </v-row>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  v-if="securityName"
                  no-gutters
                  class="mr-5"
                >
                  <v-col
                    cols="6"
                    md="3"
                    class="primary--text"
                  >
                    <span v-if="transaction.action.type === transferTypes.Exercise && linkedSecurityName">{{ linkedSecurityName }}</span>
                    <span v-else>{{ securityName }}</span>
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    class="primary--text"
                  >
                    <share-term-badge
                      :show-single="true"
                      :type="transaction.action.type === transferTypes.Exercise && linkedSecurityType ? linkedSecurityType : securityType"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-row
                      no-gutters
                      style="width: 100%"
                      class="text-capitalize"
                    >
                      <v-col>
                        {{ $date(getTransactionDate(transaction.action)) }}
                      </v-col>
                      <v-col>
                        {{ $number(transaction.action.number) }}
                      </v-col>
                      <v-col>
                        {{ transaction.action.price_per_share ? $currencyWithPrecision(transaction.action.price_per_share) : 'NA' }}
                      </v-col>
                      <v-col>
                        {{ transaction.action.registration_name ? transaction.action.registration_name : 'NA' }}
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="transaction.action.type === transferTypes.Transfer"
                      no-gutters
                      style="width: 100%"
                      class="mt-3 pb-2 fs-15 text--secondary border-bottom"
                    >
                      <v-col cols="6">
                        Transfer to
                      </v-col>
                      <v-col cols="6">
                        Transfer basis
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="transaction.action.type === transferTypes.Transfer"
                      no-gutters
                      style="width: 100%"
                      class="pt-2 text-capitalize"
                    >
                      <v-col
                        cols="6"
                      >
                        {{ transaction.action.holder_name }}
                      </v-col>
                      <v-col cols="6">
                        {{ removeUnderscore(transaction.action.transfer_basis) }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </common-trs-modal-card-text>
    </common-trs-modal-card>
  </v-dialog>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'

  const ACTION_TYPES = {
    Repurchase: 'repurchase',
    Transfer: 'transfer',
    Cancel: 'cancel',
    Vest: 'vest',
    Exercise: 'exercise',
  }

  export default {
    name: 'SecurityTransactionHistory',

    components: {
      ShareTermBadge,
    },

    props: {
      originalIssuance: {
        type: Object,
        required: true,
      },
      transactionHistory: {
        type: Array,
        required: true,
        default: () => [],
      },
      securityName: {
        type: String,
        required: true,
        default: '',
      },
      securityType: {
        type: String,
        required: true,
      },
      linkedSecurityName: {
        type: String,
        default: '',
      },
      linkedSecurityType: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      internalDialog: false,
      transferTypes: ACTION_TYPES,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      transactionHistory: {
        async handler (val) {
          if (val) {
            await Promise.all(val
              .map(v => this.updateHolderName(v.action)),
            )
          }
        },
        immediate: true,
      },
    },

    methods: {
      close () {
        this.internalDialog = false
        this.$emit('close')
      },
      getTransactionDate (action) {
        switch (action.type) {
          case ACTION_TYPES.Repurchase: return action.repurchased_on
          case ACTION_TYPES.Transfer: return action.transferred_on
          case ACTION_TYPES.Cancel: return action.canceled_on
        }
      },
      removeUnderscore (text) {
        return text ? text.replaceAll('valuation_', '') : ''
      },
      async updateHolderName (data) {
        data.holder_name = await this.getHolderNameById(data.to_holder_id) || ''
      },
      async getHolderNameById (holderId) {
        try {
          if (holderId) {
            const resp = await captableService.getProfileById(this.captableId, holderId)
            if (resp && resp.data) {
              return resp.data.name
            } else {
              return ''
            }
          }
        } catch (e) {}
      },
      print () {
        window.print()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .border-bottom {
    border-bottom: 1px solid var(--v-accent-darken1);
  }
  .fs-15 {
    font-size: 15px;
  }
  ::v-deep .v-expansion-panel {
    margin-top:10px;
  }
</style>
