<template>
  <div>
    <div
      v-if="certificateFile"
    >
      <img
        :src="certificateFile"
        alt="Issued Certificate"
        class="img-responsive"
      >
      <common-trs-btn
        type="secondary"
        class="mt-2"
        @click="download()"
      >
        Download Certificate
      </common-trs-btn>
    </div>
    <div
      v-else
      class="default-certificate-holder-wrapper"
    >
      <img
        src="../../../../../public/certificate_template.png"
        alt="No Issued Certificate"
        class="img-responsive default-certificate"
      >
      <div
        v-if="loading"
        class="default-certificate-holder"
      >
        Loading...
      </div>
      <div
        v-else
        class="default-certificate-holder"
      >
        No issued certificate
      </div>
    </div>
  </div>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable.js'

  export default {
    name: 'SecurityCertificate',

    props: {
      securityId: {
        type: String,
        required: true,
      },
      securityType: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
        default: false,
      },
    },

    data: () => ({
      certificateFile: '',
      issuedCertificateData: {},
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      securityId: {
        handler (val) {
          if (val && this.securityType) {
            this.getCertificateDocument()
          }
        },
        immediate: true,
      },
    },

    methods: {
      async getCertificateDocument () {
        try {
          const resp = await captableService.getDocuments(this.captableId, this.securityType, this.securityId)
          if (resp && resp.data) {
            const docList = resp.data.documents
            this.issuedCertificateData = docList.find(doc => doc.name === this.CONSTS.ISSUER_SECURITY_CERTIFICATE_NAME)
            if (this.issuedCertificateData) {
              const certificateData = await this.downloadCertificate(this.issuedCertificateData.id)
              this.issuedCertificateData.file = certificateData?.file
              this.certificateFile = `data:application/png;base64,${certificateData?.file}`
            }
          }
        } catch (e) {}
        this.$emit('stopLoader')
      },

      async downloadCertificate (id) {
        const resp = await captableService.downloadDoc(id)
        return resp?.data
      },
      base64ToArrayBuffer (base64) {
        const binaryString = window.atob(base64)
        const binaryLen = binaryString.length
        const bytes = new Uint8Array(binaryLen)
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i)
          bytes[i] = ascii
        }
        return bytes
      },
      download () {
        try {
          const base64ToBytes = this.base64ToArrayBuffer(this.issuedCertificateData.file)
          const blob = new Blob([base64ToBytes], { type: 'image/png' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = this.issuedCertificateData.name
          link.click()
        } catch (e) {}
      },
    },
  }
</script>

<style lang="scss" scoped>
  .img-responsive {
    width: 100%;
  }
  .default-certificate-holder-wrapper {
    position: relative;
      .default-certificate {
        opacity: .2;
      }
      .default-certificate-holder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-weight: 600;
        color: var(--v-info-lighten5);
    }
  }
</style>
