<template>
  <div>
    <security-detail-index
      :certificate-details="certificateData"
      :issuer-details="issuerData"
      :share-term-data="shareTermData"
      :docs-data="docsData"
      :notes-data="notesData"
      :holders-details="holdersDetails"
      :term-type="termType"
    />
  </div>
</template>

<script>
  import SecurityDetailIndex from '../../components/SecurityDetailIndex'

  export default {
    name: 'IndexShares',
    components: {
      SecurityDetailIndex,
    },
    props: {
      optionTermData: {
        type: Object,
        default: () => {},
      },
      optionData: {
        type: Object,
        default: () => {},
      },
      financingData: {
        type: Object,
        default: () => {},
      },
      docsData: {
        type: Array,
        default: () => [],
      },
      notesData: {
        type: Array,
        default: () => [],
      },
      underlyingShareTerm: {
        type: Object,
        default: () => {},
      },
      holdersDetails: {
        type: Object,
        default: () => {},
      },
      termType: {
        type: String,
        default: '',
      },
    },
    computed: {
      certificateData () {
        return this.getCertificateData()
      },
      issuerData () {
        return this.getIssuerData()
      },
      shareTermData () {
        return this.getShareTermData()
      },
      captableData () {
        return this.$store.getters['auth/currentOrganization']
      },

    },
    methods: {
      getCertificateData () {
        let certificateData = {}
        if (this.optionTermData && this.optionData) {
          certificateData = {
            security_type: this.CONSTS.SECURITIES_PREFIX.OPTION.toUpperCase(),
            option_type: this.optionData?.type,
            registration_number: this.optionData?.registration_name?.toUpperCase(),
            options_granted: this.$number(this.optionData?.number_granted),
            exercise_price_per_share: this.$currencyWithPrecision(this.optionData?.exercise_price),
            grant_date: this.$date(this.optionData.granted_on),
            expiration_date: this.$date(this.optionData.expires_on),
          }
        }
        return certificateData
      },

      getIssuerData () {
        let issuerData = {}
        if (this.optionTermData && this.optionData) {
          issuerData = {
            issued_by: this.captableData?.name,
            equity_incentive_plan: this.optionTermData?.name,
            board_approval_date: this.$date(this.optionData?.board_approved_on),
          }
        }
        return issuerData
      },

      getShareTermData () {
        let shareTermData = {}
        if (this.optionTermData && Object.keys(this.optionTermData)?.length > 0 && this.optionData && Object.keys(this.optionData)?.length > 0) {
          shareTermData = {
            security_type: this.underlyingShareTerm?.prefix,
            security_name_$_prefix: `${this.underlyingShareTerm?.name} / ${this.underlyingShareTerm?.prefix}`,
            voting_power: this.$number(this.underlyingShareTerm?.voting_power),
          }
          if (this.underlyingShareTerm && this.underlyingShareTerm?.type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED) {
            const preferredShare = this.underlyingShareTerm?.preferred_share_terms
            const liquidationPreference = preferredShare ? preferredShare?.liquidation_preference : null
            const participatingPreference = preferredShare && liquidationPreference ? liquidationPreference?.participating_preference : null
            if (preferredShare) {
              shareTermData.conversion_ratio = preferredShare?.conversion_ratio
              shareTermData.dividends = this.$currencyWithPrecision(liquidationPreference?.dividends)
              shareTermData.interest = liquidationPreference?.interest_terms?.type
              shareTermData.interest_rate = liquidationPreference?.include_interest && `${liquidationPreference?.interest_terms?.rate}% ${liquidationPreference?.interest_terms?.period}`
              shareTermData.priority = ''
              shareTermData.multiple = `${this.$number(liquidationPreference?.multiple)}x`
              shareTermData.participating_preference = participatingPreference?.participating_cap
            }
          }
        }
        return shareTermData
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
