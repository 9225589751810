<template>
  <div class="summary-view-wrapper px-5 py-2">
    <v-row v-if="!isOption">
      <v-col>
        <div class="d-flex align-center">
        <share-term-badge
          :show-single="true"
          :type="securityType"
        />
        <span class="font-weight-medium">
          {{ securityName }}
        </span>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="isVestingAvailable">
      <v-col
        cols="12"
        md="4"
      >
        <div class="font-weight-medium">
          {{ holderDetails.name }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          {{ holderDetails.email }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <div
          class="font-weight-medium"
        >
          {{ $number(securityDetails.numberOfSharesGranted) }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          {{ grantedandExercise }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <div
          class="font-weight-medium"
        >
          {{ $currencyWithPrecision(securityDetails.pricePerShare) }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          {{ pricePerShare }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <div
          v-if="isOption"
          class="font-weight-medium"
        >
          {{ $currencyWithPrecision(securityDetails.investedCapital) }}
        </div>
        <div
          v-else
          class="font-weight-medium"
        >
          {{ $currencyWithPrecision(securityDetails.investedCapital) }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          {{ investedOption }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <div
          class="font-weight-medium"
        >
          {{ $date(securityDetails.grantDate) }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          Grant date
        </div>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="mt-0"
    >
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-medium">
          {{ holderDetails.name }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          {{ holderDetails.email }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <div
          v-if="isConvertible"
          class="font-weight-medium"
        >
          {{ $currencyWithPrecision(securityDetails.investedCapital) }}
        </div>
        <div
          v-else
          class="font-weight-medium"
        >
          {{ $number(securityDetails.numberOfSharesGranted) }}
        </div>
        <div class="trsText--text text-body-1 text--lighten-1">
          {{ grantedSecurityType }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import ShareTermBadge from './ShareTermBadge'

  export default {
    name: 'SecuritiesSummaryView',

    components: {
      ShareTermBadge,
    },

    props: {
      securityType: {
        type: String,
        required: true,
      },
      securityDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      holderDetails: {
        type: Object,
        required: true,
        default: () => {},
      },
      isVestingAvailable: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      grantedSecurityType () {
        if (this.isOption) {
          return 'No. of options'
        } else if (this.securityType === this.CONSTS.SHARE_TERM_TYPES.WARRANT) {
          return 'Total no. of warrants'
        } else if (this.isConvertible) {
          return 'Invested capital'
        } else {
          return 'Total no. of shares'
        }
      },
      grantedandExercise () {
        if (this.isOption) {
          return 'No. of options'
        } else {
          return 'Granted shares '
        }
      },
      pricePerShare () {
        if (this.isOption) {
          return 'Exercise price per share'
        } else {
          return 'Price per share'
        }
      },
      investedOption () {
        if (this.isOption) {
          return 'Option type'
        } else {
          return 'Invested capital'
        }
      },
      isConvertible () {
        return this.securityType === this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE ||
          this.securityType === this.CONSTS.CONVERTIBLE_TERM_TYPES.SAFE ||
          this.securityType === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT
      },
      isOption () {
        return this.securityType === this.CONSTS.SHARE_TERM_TYPES.OPTION ||
          this.securityType === this.CONSTS.OPTIONS_TYPE.ISO ||
          this.securityType === this.CONSTS.OPTIONS_TYPE.NSO ||
          this.securityType === this.CONSTS.OPTIONS_TYPE['ISO/NSO']
      },
      securityName () {
        if (this.securityType === this.CONSTS.SHARE_TERM_TYPES.OPTION ||
          this.securityType === this.CONSTS.OPTIONS_TYPE.ISO ||
          this.securityType === this.CONSTS.OPTIONS_TYPE.NSO ||
          this.securityType === this.CONSTS.OPTIONS_TYPE['ISO/NSO']) {
          return 'Options'
        } else {
          return this.securityDetails.name
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .summary-view-wrapper {
    background: var(--v-trsBack-base);
    border-radius: 4px;
  }
</style>
