<template>
  <div>
    <security-detail-index
      :certificate-details="certificateData"
      :issuer-details="issuerData"
      :share-term-data="shareTermData"
      :docs-data="docsData"
      :notes-data="notesData"
      :holders-details="holdersDetails"
      :term-type="termType"
      :convertible-details="convertibleDetails"
    />
  </div>
</template>

<script>
  import SecurityDetailIndex from '../../components/SecurityDetailIndex'

  export default {
    name: 'IndexShares',
    components: {
      SecurityDetailIndex,
    },
    props: {
      convertibleTermData: {
        type: Object,
        default: () => {},
      },
      convertibleData: {
        type: Object,
        default: () => {},
      },
      financingData: {
        type: Object,
        default: () => {},
      },
      docsData: {
        type: Array,
        default: () => [],
      },
      notesData: {
        type: Array,
        default: () => [],
      },
      underlyingShareTerm: {
        type: Object,
        default: () => {},
      },
      holdersDetails: {
        type: Object,
        default: () => {},
      },
      termType: {
        type: String,
        default: '',
      },
    },
    computed: {
      certificateData () {
        return this.getCertificateData()
      },
      issuerData () {
        return this.getIssuerData()
      },
      shareTermData () {
        return this.getShareTermData()
      },
      captableData () {
        return this.$store.getters['auth/currentOrganization']
      },
      convertibleDetails () {
        return this.getConvertiblleTerm()
      },
    },
    methods: {
      getCertificateData () {
        let certificateData = {}
        if (this.convertibleTermData && this.convertibleData) {
          certificateData = {
            security_type: this.CONSTS.SECURITIES_PREFIX.CONVERTIBLE.toUpperCase(),
            convertible_name_$_prefix: `${this.convertibleTermData?.name} / ${this.convertibleTermData?.prefix}`,
            registration_number: this.convertibleData.registration_name?.toUpperCase(),
            invested_capital: this.$currencyWithPrecision(this.convertibleData?.amount_invested),
            grant_date: this.$date(this.convertibleData?.issued_on),
            maturity_date: this.$date(this.convertibleData?.matures_on),
          }
        }
        return certificateData
      },

      getIssuerData () {
        let issuerData = {}
        if (this.convertibleTermData && this.convertibleData) {
          issuerData = {
            issued_by: this.captableData?.name,
            financing_round: this.financingData?.name,
            board_approval_date: this.$date(this.convertibleData?.board_approved_on),
          }
        }
        return issuerData
      },

      getConvertiblleTerm () {
        let convertibleTermData = {}
        if (this.convertibleTermData && Object.keys(this.convertibleTermData)?.length > 0 && this.convertibleData && Object.keys(this.convertibleData)?.length > 0) {
          convertibleTermData = {
            valuation_cap: this.$currencyWithPrecision(this.convertibleTermData?.valuation_cap),
            conversion_discount: ((this.convertibleTermData?.conversion_discount) * 100).toFixed(2) + '%',
            conversion_trigger: this.$currencyWithPrecision(this.convertibleTermData?.conversion_trigger),
          }
          if (this.convertibleTermData?.interest_terms !== null) {
            const interstPeriod = this.CONSTS.INTEREST_PERIOD.find(o => o.value === this.convertibleTermData?.interest_terms?.period)
            convertibleTermData.interes = this.convertibleTermData?.interest_terms?.type
            convertibleTermData.interest_rate = ((this.convertibleTermData?.interest_terms?.rate) * 100).toFixed(2) + '%' + ' ' + interstPeriod?.title
          }
        }
        return convertibleTermData
      },
      getShareTermData () {
        let shareTermData = {}
        if (this.convertibleTermData && this.convertibleData) {
          shareTermData = {
            security_type: this.underlyingShareTerm?.prefix,
            security_name_$_prefix: `${this.underlyingShareTerm?.name} / ${this.underlyingShareTerm?.prefix?.toUpperCase()}`,
            voting_power: this.$number(this.underlyingShareTerm?.voting_power),
          }
          if (this.underlyingShareTerm && this.underlyingShareTerm?.type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED) {
            const preferredShare = this.underlyingShareTerm?.preferred_share_terms
            const liquidationPreference = preferredShare ? preferredShare?.liquidation_preference : null
            const participatingPreference = preferredShare && liquidationPreference ? liquidationPreference?.participating_preference : null
            if (preferredShare) {
              shareTermData.conversion_ratio = preferredShare?.conversion_ratio
              shareTermData.dividends = this.$currencyWithPrecision(liquidationPreference?.dividends)
              shareTermData.interest = liquidationPreference?.interest_terms?.type
              shareTermData.interest_rate = liquidationPreference?.include_interest && `${liquidationPreference?.interest_terms?.rate}% ${liquidationPreference?.interest_terms.period}`
              shareTermData.priority = ''
              shareTermData.multiple = `${this.$number(liquidationPreference?.multiple)}x`
              shareTermData.participating_preference = participatingPreference?.participating_cap
            }
          }
        }
        return shareTermData
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
