<template>
  <div>
    <security-detail-index
      :certificate-details="certificateData"
      :issuer-details="issuerData"
      :share-term-data="shareTermsData"
      :docs-data="docsData"
      :notes-data="notesData"
      :holders-details="holdersDetails"
    />
  </div>
</template>

<script>
  import SecurityDetailIndex from '../../components/SecurityDetailIndex'

  export default {
    name: 'IndexShares',
    components: {
      SecurityDetailIndex,
    },
    props: {
      shareTermData: {
        type: Object,
        default: () => {},
      },
      shareData: {
        type: Object,
        default: () => {},
      },
      financingData: {
        type: Object,
        default: () => {},
      },
      docsData: {
        type: Array,
        default: () => [],
      },
      notesData: {
        type: Array,
        default: () => [],
      },
      holdersDetails: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      certificateData () {
        return this.getCertificateData()
      },
      issuerData () {
        return this.getIssuerData()
      },
      shareTermsData () {
        return this.getShareTermData()
      },
      captableData () {
        return this.$store.getters['auth/currentOrganization']
      },
    },

    methods: {
      getCertificateData () {
        let certificateData = {}
        if (this.shareTermData && this.shareData) {
          certificateData = {
            security_type: this.shareTermData.type,
            security_name_$_prefix: `${this.shareTermData?.name} / ${this.shareTermData?.prefix?.toUpperCase()}`,
            registration_number: this.shareData?.registration_name?.toUpperCase(),
            shares: this.$number(this.shareData.number),
            price_per_share: this.$currencyWithPrecision(this.shareData.price_per_share),
            original_aquisition_date: this.$date(this.shareData.granted_on),
            cost_basis: this.$date(this.shareData.expires_on),
          }
        }
        return certificateData
      },

      getIssuerData () {
        let issuerData = {}
        if (this.shareTermData && this.shareData) {
          issuerData = {
            issued_by: this.captableData?.name,
            financing_round: this.financingData?.name,
            board_approval_date: this.$date(this.shareData?.board_approved_on || this.shareData?.granted_on),
          }
        }
        return issuerData
      },
      getShareTermData () {
        let shareTermData = {}
        if (this.shareTermData && Object.keys(this.shareTermData)?.length > 0 && this.shareData && Object.keys(this.shareData)?.length > 0) {
          shareTermData = {
            voting_power: this.$number(this.shareTermData.voting_power),
          }
          if (this.shareTermData && this.shareTermData.type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED) {
            const preferredShare = this.shareTermData.preferred_share_terms
            const liquidationPreference = preferredShare ? preferredShare.liquidation_preference : null
            const participatingPreference = preferredShare && liquidationPreference ? liquidationPreference.participating_preference : null
            if (preferredShare) {
              shareTermData.conversion_ratio = preferredShare?.conversion_ratio
              shareTermData.dividends = this.$currencyWithPrecision(liquidationPreference?.dividends)
              shareTermData.interest = liquidationPreference?.interest_terms?.type
              shareTermData.interest_rate = liquidationPreference?.include_interest && `${liquidationPreference?.interest_terms?.rate}% ${liquidationPreference?.interest_terms?.period}`
              shareTermData.priority = '' // 'need to check its not available'
              shareTermData.multiple = `${this.$number(liquidationPreference?.multiple)}x`
              shareTermData.participating_preference = participatingPreference?.participating_cap
            }
          }
        }
        return shareTermData
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
