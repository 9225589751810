<template>
  <div
    class="container px-4"
  >
    <div class="px-2 py-3 text-h4 font-weight-medium">
      {{ title }}
    </div>
    <div
      v-for="(value,key) in shareItems"
      :key="key"
      class="px-4"
    >
      <v-row class="card-row">
        <v-col
          cols="12"
          lg="6"
          md="12"
          class="text-capitalize card-col "
        >
          {{ formatKey(key) }}
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="12"
        >
          <span v-if="value">{{ !isPreFix(key) ? capitalize(value) : value }}</span>
          <span v-else>-</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ShareHolders',
    props: {
      shareItems: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: '',
      },
    },
    methods: {
      formatKey (str) {
        return str.split('$').join('/').split('_').join(' ')
      },
      capitalize (str) {
        if (str && str.includes('@')) { // skiping capitalize for email
          return str
        } else {
          return str && str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
        }
      },
      isPreFix (key) {
        return (key.indexOf('prefix') > -1 || key.indexOf('registration') > -1)
      },
    },
  }
</script>
<style lang="scss" scoped>
.card-row {
   border: 1px solid #e5e5e5;
}
.card-col {
   background: var(--v-trsBack-base);
}
</style>
